<template>
  <v-container class="fill-height">
    <!-- <v-row
      class="justify-center align-center"
      :class="$vuetify.breakpoint.mdAndUp && 'rounded-xl'"
      style="background-color: #fff; box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;"
      :style="$vuetify.breakpoint.smAndDown && 'height: 100vh'"
    > -->
    <v-row class="justify-center align-center">
      <!-- <v-col md="7" xl="4" class="d-none d-md-flex justify-center align-center">
        <v-img
          contain
          max-height="600"
          style="opacity: 0.8"
          :src="require('@/assets/images/login.png')"
        ></v-img>
      </v-col> -->
      <v-col cols="12" sm="8" md="5" xl="3">
        <v-card
          class="py-10 rounded-xl"
          style="
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
          "
        >
          <v-img
            contain
            height="30"
            :src="require('@/assets/images/logo_esoda.png')"
          ></v-img>
          <v-card-title class="justify-center text-h4 font-weight-bold py-4"
            >Login Administrator</v-card-title
          >
          <v-card-subtitle
            class="text-center grey--text text--darken-1 subtitle-1"
            >Masuk dengan Akun Otodidak Anda</v-card-subtitle
          >
          <v-card-text
            class="d-flex flex-column align-start mt-6"
            :class="$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10'"
          >
            <v-form
              style="width: 100%"
              ref="form-login"
              v-model="valid"
              lazy-validation
              @keyup.native.enter="doLogin"
            >
              <v-text-field
                autofocus
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Username atau email"
                prepend-inner-icon="mdi-account-outline"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.credential"
                :error-messages="validation.credential"
                @keyup="validation.credential = ''"
                :rules="[
                  (v) => !!v || `Username atau email tidak boleh kosong`,
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-text-field
                dense
                solo
                autocomplete="new-password"
                :type="showPassword ? 'text' : 'password'"
                color="#4ab762"
                placeholder="Password"
                prepend-inner-icon="mdi-form-textbox-password"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.password"
                @keyup="validation.password = ''"
                :error-messages="validation.password"
                :rules="[(v) => !!v || `Password tidak boleh kosong`]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-form>
            <div
              class="d-flex flex-row justify-space-between"
              style="width: 100%"
            >
              <v-checkbox
                dense
                class="mt-0 pt-0 checkbox-pwd"
                v-model="showPassword"
                color="#4ab762"
                :label="!showPassword ? 'Show password' : 'Hide password'"
                hide-details
              ></v-checkbox>
              <v-btn
                color="#4ab762"
                text
                class="text-none px-0"
                depressed
                small
                @click="$router.push({ name: 'forgotPassword' })"
                >Lupa password?</v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions
            class="justify-center"
            :class="$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10'"
          >
            <v-btn
              large
              block
              depressed
              color="#4ab762"
              class="font-weight-bold white--text text-none rounded-lg"
              @click="doLogin"
              :disabled="loading"
              :loading="loading"
            >
              <div class="flex-grow-1 ml-6 title">Masuk</div>
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-snackbar
      v-model="snackbar.show"
      multi-line
      bottom
      left
      :color="snackbar.color"
      text
      outlined
      max-width="300"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar.color"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          BAIK
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-container>
</template>

<script>
import { TokenService } from "@/services/service.js";

export default {
  name: "Login",

  data: () => ({
    valid: true,
    loading: false,

    showPassword: false,
    form: {
      credential: "",
      password: "",
      device: "web",
    },
    validation: {
      credential: "",
      password: "",
      device: "",
    },

    snackbar: {
      show: false,
      text: "",
      color: "#e74c3c",
    },
  }),

  methods: {
    async doLogin() {
      let isValid = this.$refs["form-login"].validate();

      if (isValid) {
        this.loading = true;
        let response = await this.$post("admin/login", this.form);

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Tunggu sebentar, Anda akan diarahkan ke halaman Administrator",
            color: "#3498db",
          };
          TokenService.saveUser(response.results.data);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (response.status == 412) {
          this.loading = false;
          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.loading = false;
          this.$store.state.snackbar = {
            show: true,
            text: "Username atau password salah",
            color: "#e74c3c",
          };
        }
      }
    },
  },
};
</script>

<style>
</style>